<template>
  <v-main>
    <slot></slot>
  </v-main>
</template>
<script>
export default {
  name: 'FullScreenLayout'
};
</script>
